@import '@angular/material/theming';

@mixin dashboard-theme($theme) {
	$primary: $mat-palette-primary;
	$shades: $mat-palette-shades;
	$warn: $mat-palette-warn;

	.border-bottom {
		border-bottom: 1px solid mat-color($shades, A700);
	}

	.border-bottom-2 {
		border-bottom: 2px solid mat-color($shades, 400);
	}

	.border-bottom-warn {
		border-bottom: 2px solid mat-color($warn, 700);
	}

	.border-bottom-gray {
		border-bottom: 2px solid #8e939b;
	}

	.filter-body {
		mat-checkbox {
			&:nth-child(odd) {
				background-color: mat-color($shades, A200);
			}

			&:hover {
				background-color: mat-color($shades, A400);
			}
		}
	}
	.custom-btn {
		background-color: mat-color($shades, A400);
		&:hover {
			background-color: mat-color($shades, A700);
		}
	}

	app-dashboard {
		.active {
			color: #1b273c !important;
			border-bottom: 2px solid #1b273c;
		}
		.option {
			color: #616875;
		}
	}

	.color-value-0 {
		color: $color-bg-no-report;
	}

	.color-has-kiosk {
		color: $color-total-instance;
	}

	.color-pending {
		color: $color-pending-instance;
	}

	.device-overview {
		&--right {
			&--item {
				&--top {
					&--number {
						background: $primary-white;
					}
					border-bottom: 1px solid $color-larger-than-120;
					&--unlicensed__height {
						background: linear-gradient(180deg, #777d89 0%, rgba(119, 125, 137, 0) 100%);
					}
					&--zero-days__height {
						background: linear-gradient(180deg, #cb0704 0%, rgba(203, 7, 4, 0) 100%);
					}
					&--zero-to-30-days__height {
						background: linear-gradient(180deg, #ed6706 0%, rgba(119, 125, 137, 0) 100%);
					}
					&--30-to-90-days__height {
						background: linear-gradient(180deg, #13b213 0%, rgba(19, 178, 19, 0) 100%);
					}
					&--90-plus-days__height {
						background: linear-gradient(180deg, #166e16 0%, rgba(22, 110, 22, 0) 100%);
					}
				}
			}
		}
	}

	.expired {
		color: $color-expired;
	}
	.expired-bg {
		background-color: $color-expired;
		color: $primary-white;
	}

	.bg-color-group {
		background-color: #cbd3e7;

		&:hover {
			background-color: #c3cce4;
		}
	}

	.bg-color-instance {
		background-color: #e9ecf2;
		&:hover {
			background-color: #dee4f0;
		}
	}
}
