@import '@angular/material/theming';

@mixin event-component-theme($theme) {
	$shades: $mat-palette-shades;
	app-event-history-list {
		.log-config-diff {
			.highlight {
				background-color: $tertiary-highlight !important;
			}
		}
		.divider {
			width: 1px;
			height: 20px;
			background-color: mat-color($shades, 50);
			opacity: 0.5;
		}
	}
}
