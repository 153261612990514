@import '@angular/material/theming';

@mixin reset-pin-theme($theme) {
	$primary: $mat-palette-primary;
	$warn: $mat-palette-warn;
	$shades: $mat-palette-shades;
	$success: $mat-palette-success;
	$highlight: $mat-palette-highlight;

	.invalid-error {
		margin-top: 5px;
		font-size: 12px;
		color: mat-color($warn);
		position: absolute;
	}

	.has-error {
		.mat-form-field-appearance-outline .mat-form-field-outline {
			color: mat-color($warn) !important;
		}
	}
}
