@import '@angular/material/theming';

@mixin application-group-configuration-dialog-theme($theme) {
	$warn: $mat-palette-warn;

	app-configuration-instance {
		.configuration-version {
			.config-icon {
				&.highlight {
					color: $tertiary-highlight;
				}
			}

			&.warning {
				color: $tertiary-highlight;
			}

			&.danger {
				color: mat-color($warn, 700);
			}

			&.primary {
				color: $primary-blue;
			}

			&.active {
				color: $primary-white;

				.config-icon {
					color: $primary-white;
				}
			}
		}
	}
}
