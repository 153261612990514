@import '@angular/material/theming';

@mixin adding-new-group-theme($theme) {
	$shades: $mat-palette-shades;

	app-adding-new-group {
		.selecting-table {
			height: 270px;
			overflow-y: auto;
			background-color: mat-color($shades, A700, 30%);

			.row-table {
				height: 47px;
				background-color: mat-color($shades, A700, 30%);
				color: $primary-dark;
			}

			.active-row {
				background-color: $primary-blue !important;
				color: $primary-white;
			}
		}

		.adding-header {
			padding: 10px 0;
		}
	}
}
