@mixin my-feedback-theme($theme) {
	app-my-feedback {
		.mat-radio-group.ng-invalid .mat-radio-button .mat-radio-outer-circle {
			border-color: mat-color($palette-opswat-shades, 100);
		}
		.mat-input-element {
			&::placeholder {
				color: $tertiary-warn;
			}
		}
	}
}
