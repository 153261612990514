@import '@angular/material/theming';

@mixin custom-tooltip-theme($theme) {
	$primary: $mat-palette-primary;
	$shades: $mat-palette-shades;

	.tooltip-container {
		border: 2px solid $primary-white;
		background: $bg-neutrals-950;
	}
}
