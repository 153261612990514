@import '@angular/material/theming';

@mixin app-create-confirmation-dialog-theme($theme) {
	$warn: $mat-palette-warn;
	$success: $mat-palette-success;
	$shades: $mat-palette-shades;

	app-create-confirmation-dialog {
		.instances-table {
			tbody tr {
				&:nth-child(2n + 1) {
					background-color: rgba(mat-color($shades, 50), 0.3);
				}
			}
		}

		.success {
			color: mat-color($success, 500);
		}

		.warning {
			color: $tertiary-highlight;
		}

		.danger {
			color: mat-color($warn, 700);
		}
	}
}
