@import '@angular/material/theming';

@mixin footer-component-theme($theme) {
	$custom: $custom-colors-theme-default;
	app-footer {
		footer {
			color: mat-color($custom, tertiary-text);
			ul {
				li {
					a {
						color: mat-color($custom, tertiary-text);
					}
				}
			}
		}
	}
}
