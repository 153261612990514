@mixin app-kiosk-group($theme) {
	$primary: $mat-palette-primary;
	$success: $mat-palette-success;

	.mat-button.rename-group-selected {
		color: mat-color($primary, 500);
	}

	.input-wrapper {
		position: relative;
	}

	.default-label {
		background-color: mat-color($success, 700);
		color: $primary-white;
		padding: 2px 8px;
		border-radius: 0px;
		font-size: 11px;

		&--input {
			margin-top: 5px;
		}
	}

	.circle-check {
		color: mat-color($success);
	}

	.unwanted {
		color: $tertiary-highlight;
	}
}
