@import '@angular/material/theming';
@import '../../app/views/content-view/layout/navigation-main/navigation-main.component.theme.scss';
@import './../../app/views/content-view/modules/settings/license/license.component.theme.scss';
@import './../../app/views/content-view/modules/settings/account/account.component.theme.scss';
@import './../../app/views/content-view/modules/settings/license/kiosk-active-license-dialog/kiosk-active-license-dialog.component.theme.scss';
@import './../../app/views/content-view/modules/inventory/metadefender-kiosk/kiosk-scan-history/kiosk-scan-history.component.theme.scss';
@import './../../app/views/content-view/modules/inventory/metadefender-kiosk/kiosk-settings/kiosk-settings.component.theme.scss';
@import './../../app/views/content-view/modules/inventory/metadefender-kiosk/kiosk-group-detail-instance/kiosk-group-detail-instance.component.theme.scss';
@import './../../app/views/content-view/modules/inventory/metadefender-core/core-group-detail-instance/core-group-detail-instance.component.theme.scss';

@import './../../app/shared/components/deleting-group-dialog/deleting-group-dialog.component.theme.scss';
@import './../../app/views/content-view/modules/inventory/metadefender-kiosk/add-new-group/add-new-group.theme.scss';
@import './../../app/views/content-view/modules/inventory/metadefender-product/adding-new-group/adding-new-group.theme.scss';
@import './../../app/views/content-view/modules/inventory/metadefender-product/product-instance/product-instance.component.theme.scss';
@import './../../app/views/content-view/modules/inventory/metadefender-core/core-instance/core-instance.component.theme.scss';
@import './../../app/views/content-view/modules/inventory/metadefender-core/core-configuration/core-configuration.component.theme.scss';
@import './../../app/shared/components/adding-instance-dialog/adding-instance-dialog.component.theme.scss';
@import './../../app/shared/components/moving-to-another-group-dialog/moving-to-another-group-dialog.component.theme.scss';
@import './../../app/views/content-view/modules/event-history/event-history-list/event-history-list.component.theme.scss';
@import './../../app/shared/components/create-confirmation-dialog/create-confirmation-dialog.component.theme.scss';
@import './../../app/views/content-view/modules/inventory/metadefender-kiosk/kiosk-group/kiosk-group.component.theme.scss';
@import './../../app/views/content-view/modules/inventory/metadefender-core/core-group/core-group.component.theme.scss';
@import './../../app/views/content-view/modules/inventory/metadefender-kiosk/kiosk-group-detail-settings/kiosk-group-detail-settings.component.theme.scss';
@import './../../app/views/content-view/modules/inventory/metadefender-kiosk/kiosk-instance/kiosk-instance.component.theme.scss';
@import './../../app/views/content-view/modules/user-management/users/users.component.theme.scss';
@import './../../app/shared/components/group-info/group-info.component.theme.scss';
@import './../../app/shared/components/application-group-configuration-dialog/application-group-configuration-dialog.component.theme.scss';
@import './../../app/shared/components/result-confirmation/result-confirmation.component.theme.scss';
@import './../../app/views/content-view/modules/user-management/roles/roles.component.theme.scss';
@import '../../app/shared/components/pin-verification-dialog/pin-verification-dialog.component.theme.scss';
@import './../../app/shared/components/configuration-instance/configuration-instance.component.theme.scss';
@import './../../app/views/content-view/modules/settings/reset-pin/reset-pin.component.theme.scss';
@import './../../app/views/content-view/layout/header/my-feedback/my-feedback.component.theme.scss';
@import './../../app/shared/components/toast/toast.component.scss';
@import './../../app/shared/components/survey/survey.component.theme.scss';
@import './../../app/views/content-view/modules/inventory/metadefender-kiosk/overview/dashboard/free-space-chart/free-space-chart.component.theme.scss';
@import './../../app/views/content-view/modules/inventory/metadefender-kiosk/overview/dashboard/combo-chart/combo-chart.component.theme.scss';
@import './../../app/shared/components/pin-validation-dialog/pin-validation-dialog.component.theme.scss';
@import './../../app/shared/components/vpack-pin-validation-dialog/vpack-pin-validation-dialog.component.theme.scss';
@import './../../app/shared/components/custom-tooltip/custom-tooltip.component.theme.scss';
@import './../../app/views/content-view/modules/inventory/metadefender-kiosk/overview/dashboard/bar-chart/bar-charts.component.theme.scss';
@import './../../app/views/content-view/modules/inventory/metadefender-kiosk/overview/dashboard/dashboard.component.theme.scss';
@import './../../app/views/content-view/modules/inventory/metadefender-media-firewall/media-firewall-group-detail-instance/media-firewall-group-detail-instance.component.theme.scss';
@import './../../app/views/content-view/modules/inventory/metadefender-media-firewall/media-firewall-scan-history/media-firewall-scan-history.component.theme.scss';
@import './../../app/views/content-view/modules/inventory/inventory-overview/inventory-overview.component.theme.scss';
@import './../../app/views/content-view/modules/inventory/metadefender-media-firewall/media-firewall-page-title/media-firewall-page-title.component.theme.scss';
@import './../../app/views/content-view/modules/inventory/metadefender-media-firewall/media-firewall-pin-validation-dialog/media-firewall-pin-validation-dialog.component.theme.scss';
@import './../../app/views/content-view/modules/inventory/metadefender-core/core-group-detail-configuration/core-group-detail-configuration.component.theme.scss';
@import './../../app/views/content-view/modules/inventory/metadefender-managed-file-transfer/mft-configuration/mft-configuration.component.theme.scss';
@import './../../app/views/content-view/modules/inventory/metadefender-managed-file-transfer/mft-group-detail-configuration/mft-group-detail-configuration.component.theme.scss';
@import './../../app/views/content-view/modules/inventory/metadefender-icap/metadefender-icap-configuration/metadefender-icap-configuration.component.theme.scss';
@import './../../app/views/content-view/modules/inventory/metadefender-icap/metadefender-icap-group-detail-configuration/metadefender-icap-group-detail-configuration.component.theme.scss';

@mixin app-components-theme($theme) {
	@include navigation-main-theme($theme);
	@include license-theme($theme);
	@include account-theme($theme);
	@include kiosk-active-license-dialog-theme($theme);
	@include kiosk-group-detail-theme($theme);
	@include core-group-detail-theme($theme);
	@include add-new-group-theme($theme);
	@include deleting-group-dialog-theme($theme);
	@include adding-instance-dialog-theme($theme);
	@include moving-to-another-group-dialog-theme($theme);
	@include event-component-theme($theme);
	@include app-create-confirmation-dialog-theme($theme);
	@include app-kiosk-group($theme);
	@include app-core-group($theme);
	@include kiosk-group-detail-settings-theme($theme);
	@include kiosk-instance-theme($theme);
	@include kiosk-scan-history-theme($theme);
	@include media-firewall-scan-history-theme($theme);
	@include kiosk-settings-theme($theme);
	@include core-configuration-theme($theme);
	@include metadefender-icap-configuration-theme($theme);
	@include mft-configuration-theme($theme);
	@include users-theme($theme);
	@include reset-pin-theme($theme);
	@include group-info-theme($theme);
	@include application-group-configuration-dialog-theme($theme);
	@include result-confirmation-theme($theme);
	@include role-theme($theme);
	@include pin-verification-dialog-component-theme($theme);
	@include pin-validation-dialog-component-theme($theme);
	@include vpack-pin-validation-dialog-component-theme($theme);
	@include app-toast-theme($theme);
	@include app-toast-theme($theme);
	@include survey-theme($theme);
	@include free-space-chart-theme($theme);
	@include combo-chart-theme($theme);
	@include custom-tooltip-theme($theme);
	@include bar-charts-theme($theme);
	@include dashboard-theme($theme);
	@include my-feedback-theme($theme);
	@include media-firewall-group-detail-theme($theme);
	@include adding-new-group-theme($theme);
	@include product-instance-theme($theme);
	@include core-instance-theme($theme);
	@include inventory-overview-theme($theme);
	@include media-firewall-page-component-theme($theme);
	@include media-firewall-pin-validation-dialog-component-theme($theme);
	@include core-group-detail-configuration-theme($theme);
	$shades: $mat-palette-shades;
	$custom: $custom-colors-theme-default;
	$warn: $mat-palette-warn;
	$success: $mat-palette-success;
	$highlight: $mat-palette-highlight;

	*:not(mat-sidenav-content)::-webkit-scrollbar-thumb {
		border-color: mat-color($shades, A700);
		background-color: mat-color($shades, A700);
	}

	// work on firefox
	@supports not selector(::-webkit-scrollbar) {
		*:not(mat-sidenav-content) {
			scrollbar-color: mat-color($shades, A700) mat-color($shades, A400);
		}
	}

	// move all global styles to the top level
	.incident {
		&-critical {
			color: mat-color($warn, 700);
		}
		&-high {
			color: mat-color($warn, 400);
		}
		&-medium {
			color: mat-color($highlight, 500);
		}
		&-none {
			color: mat-color($shades, 500);
		}
		&-info {
			color: mat-color($shades, 500);
		}
	}

	.mat-menu-content {
		.mat-menu-item {
			&:hover {
				background-color: mat-color($shades, A200) !important;
			}
		}

		mat-divider {
			border-top-color: mat-color($shades, A400);
		}
	}

	mat-icon.tooltip-icon {
		color: mat-color($shades, 200);

		&:hover {
			color: $primary-blue;
		}
	}

	.notification-panel {
		.notification-item {
			border-bottom-color: mat-color($shades, A200);
		}

		.info {
			background-color: mat-color($shades, A400);
		}

		.warning {
			background-color: mat-color($highlight, 500);
		}

		.error {
			background-color: mat-color($warn, 500);
		}

		.critical {
			background-color: mat-color($warn, 700);
		}
	}

	app-create-confirmation-dialog {
		.instances-table {
			tbody tr {
				&:nth-child(2n + 1) {
					background-color: rgba(mat-color($shades, 50), 0.3);
				}
			}
		}
	}

	app-create-confirmation-dialog,
	app-adding-instance-dialog {
		.success {
			color: mat-color($success, 500);
		}

		.warning {
			color: $tertiary-highlight;
		}

		.danger {
			color: mat-color($warn, 700);
		}
	}
}
