@import '@angular/material/theming';

@mixin app-toast-theme($theme) {
	.summary-message {
		font-family: mat-font-family($typography-config);
	}

	.break-spaces {
		white-space: break-spaces;
	}
}

.app-toast {
	position: fixed;
	min-width: 25rem;
	max-width: 70%;
	top: 30px;
	left: 50%;
	transform: translateX(-50%);
	overflow: visible;
	overflow-wrap: anywhere;
}
