@import '@angular/material/theming';

@mixin role-theme($theme) {
	.edit-mode.mat-cell {
		box-shadow: inset 4px 0px 0px 0px $primary-blue;
	}
	.table.mat-table tr.mat-row td.mat-cell {
		border-bottom: 0px solid transparent !important;
	}

	.role-table.mat-table tr.mat-row:not(.selected):hover {
		background: $primary-white !important;
	}

	tr.element-row:not(.expanded-row):active {
		background: $primary-white;
	}
}
