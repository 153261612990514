@import '@angular/material/theming';

@mixin survey-theme($theme) {
	$shades: $mat-palette-shades;
	$primary: $mat-palette-primary;

	.survey {
		background-color: mat-color($primary, 400);
	}
}
