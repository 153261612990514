@import '@angular/material/theming';

@mixin result-confirmation-theme($theme) {
	$shades: $mat-palette-shades;
	$success: $mat-palette-success;

	.success-panel {
		border-left: 4px solid mat-color($success, 700);
	}

	.failure-panel {
		border-left: 4px solid $tertiary-warn;
	}

	.instances-table {
		tbody tr {
			&:nth-child(2n + 1) {
				background-color: none !important;
			}
		}

		.left-column,
		.right-column {
			background-color: rgba(mat-color($shades, 50), 0.3);
		}
	}
}
