@import '@angular/material/theming';

@mixin media-firewall-pin-validation-dialog-component-theme($theme) {
	$shades: $mat-palette-shades;
	$custom: $custom-colors-theme-default;
	$warn: $mat-palette-warn;
	$highlight: $mat-palette-highlight;
	$primary: $mat-palette-primary;

	.pin-setting {
		align-items: center;
		text-align: center;
		gap: 5px;

		.label {
			text-decoration: underline;
			font-size: 14px;
			cursor: pointer;
		}

		.invalid {
			border: 1px solid mat-color($warn, 500) !important;
			&:focus {
				border: 1px solid mat-color($warn, 500) !important;
			}
		}

		.pin-label {
			height: 30px;

			.input {
				box-sizing: border-box;
				font-size: 20px;
				width: 125px;
				height: 30px;
				border: 1px solid map-get($shades, A700);
				letter-spacing: 0.5em;
				font-family: Verdana, Geneva, Tahoma, sans-serif;

				&:focus {
					outline: none !important;
					border: 1px solid map-get($shades, A700);
				}
			}
		}

		.error {
			padding-top: 3px;
			text-align: start;
			font-size: 12px;
		}

		mat-icon {
			height: 4.5px;
			width: 8px;
			color: map-get($shades, A700);
		}
	}
}
