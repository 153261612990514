@import '@angular/material/theming';

@mixin account-theme($theme) {
	$shades: $mat-palette-shades;
	$foreground: $foreground-theme-default;

	app-account {
		.grid-border {
			border: solid 1px mat-color($shades, 100);
		}

		.border-bottom {
			border-bottom: 1px solid mat-color($foreground, divider);
		}

		.revoke-code {
			font-size: 14px;
			font-weight: 500;
			background-color: $primary-dark;
			color: $primary-white;
			padding: 3px 10px;
			cursor: pointer;
			margin-left: 10px;
		}
	}
}
