app-footer {
	width: 100%;

	footer {
		@media (min-width: 800px) {
			justify-content: space-between;
			flex-direction: row;
		}

		@media (max-width: 800px) {
			align-items: center;
			flex-direction: column;
		}

		align-items: center;
		display: flex;
		padding: 25px 0;
		margin: 0 auto;
		font-size: 12px;

		ul {
			@media (min-width: 800px) {
				margin: 0;
			}

			@media (max-width: 800px) {
				margin: 0 0 10px 0;
			}

			padding: 0;
			list-style-type: none;

			li {
				float: left;
				margin-right: 12px;
			}
		}
	}
}