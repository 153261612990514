@import '@angular/material/theming';

@mixin header-component-theme($theme) {
	$shades: $mat-palette-shades;
	$custom: $custom-colors-theme-default;
	$warn: $mat-palette-warn;
	$highlight: $mat-palette-highlight;
	$primary: $mat-palette-primary;
	$success: $mat-palette-success;

	// move all global styles to the top level
	.status {
		font-weight: 500;
		font-size: 14px;
		letter-spacing: 0;
		padding: 2px 5px;
		text-align: center;
	}

	.user-info {
		&--email {
			color: mat-color($palette-opswat-grayscale, 100);
		}

		&--solid-down-icon {
			transform: translate(0px, -1px) !important;
		}
	}

	.external-link {
		color: mat-color($palette-opswat-shades, 500);

		&:hover {
			text-decoration: none;
			color: mat-color($palette-opswat-shades, 500);
		}
	}

	.operational {
		background-color: mat-color($success, 500);
		color: mat-color($primary, A100);

		&:hover {
			text-decoration: none;
			color: $primary-white;
			background-color: mat-color($success, 700);
		}

		&.child {
			background-color: unset !important;
			color: mat-color($success, 500);

			&:hover {
				text-decoration: none;
				color: mat-color($success, 500);
			}
		}
	}

	.outage {
		background-color: mat-color($warn, 500);
		color: mat-color($primary, A100);

		&:hover {
			text-decoration: none;
			color: $primary-white;
			background-color: mat-color($warn, 700);
		}

		&.child {
			background-color: unset !important;
			color: mat-color($warn, 500);

			&:hover {
				text-decoration: none;
				color: mat-color($warn, 500);
			}
		}
	}

	.degraded {
		background-color: mat-color($highlight, 500);
		color: mat-color($primary, A100);

		&:hover {
			text-decoration: none;
			color: $primary-white;
			background-color: #fc9007;
		}

		&.child {
			background-color: unset !important;
			color: mat-color($highlight, 500);

			&:hover {
				text-decoration: none;
				color: mat-color($highlight, 500);
			}
		}
	}

	// .unknown {
	// 	background-color: mat-color($shades, 400);
	// 	color: mat-color($primary, A100);

	// 	&:hover {
	// 		text-decoration: none;
	// 		color: $primary-white;
	// 		background-color: mat-color($shades, 500);
	// 	}

	// 	&.child {
	// 		background-color: unset !important;
	// 		color: mat-color($shades, 400);

	// 		&:hover {
	// 			text-decoration: none;
	// 			color: mat-color($shades, 400);
	// 		}
	// 	}
	// }

	.mat-menu-panel {
		border-radius: 0 !important;
	}

	.switch-account {
		&--item {
			font-size: 14px;
			&:hover {
				background-color: mat-color($shades, A200) !important;
			}
		}

		&--title {
			font-size: 14px;
		}

		&--current {
			position: relative;
			&::before {
				content: '';
				position: absolute;
				width: 4px;
				height: 100%;
				top: 0;
				left: 0px;
				background-color: $primary-blue;
			}
		}
	}

	.mat-menu-content {
		padding: 0 !important;
		.mat-menu-item {
			&:hover {
				background-color: mat-color($shades, A200) !important;
			}
		}

		mat-divider {
			border-top-color: mat-color($shades, A400);
		}
	}

	.account-btn {
		padding: 12px 0px 9px 10px !important;
		&:hover {
			background-color: transparent !important;
			color: mat-color($primary, 400);
		}

		.user-name {
			margin-bottom: -5px;
			display: inline-block;
			max-width: 200px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	app-header {
		a {
			color: mat-color($custom, tertiary-text);
		}
		.new-notification span.mat-button-wrapper::after {
			background-color: mat-color($warn, 500);
		}
	}

	.menu-panel ul li a {
		color: mat-color($palette-opswat-shades, 500);

		&:hover {
			text-decoration: none;
			color: mat-color($palette-opswat-shades, 500);
		}
	}

	#new-product-release-btn:hover {
		background-color: mat-color($palette-opswat-primary, 500);
	}
}
