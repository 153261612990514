@use '@angular/material' as mat;
@use 'sass:map';
@import '@angular/material/theming';

// @opswat/ui styles
@import '../../node_modules/@opswat/ui/styles/_core';
@import '../../node_modules/@opswat/ui/styles/_theme';

// import modules
@import './mixins/app-components.theme.scss';
@import './base';
@import './color-class.scss';

@import './mat-custom';
@import './variables';

// @import 'src/assets/scss/_layout.scss';
// Default theme
@import '../../node_modules/@opswat/ui/styles/themes/default-theme.palette';

@import '../app/views/content-view/layout/footer/footer.component.scss';
@import '../app/views/content-view/layout/header/header.component.scss';

@import 'app/views/content-view/layout/footer/footer.component.theme.scss';
@import 'app/views/content-view/layout/header/header.component.theme.scss';
@import 'app/views/content-view/layout/header/my-opswat/my-opswat.component.theme.scss';

@import '../../node_modules/@opswat/ui/styles/base/navigation-main.component.theme.scss';
@import 'gridstack/dist/gridstack.min.css';

@include mat.core();
@include mat.legacy_core();
$ocm-theme: mat.define-light-theme(
	(
		color: (
			primary: $mat-palette-primary,
			accent: $mat-palette-shades,
		),
		typography: $typography-config,
	)
);

$ocm-theme: map.set($ocm-theme, color, foreground, $foreground-theme-default);

$ocm-theme: map.set($ocm-theme, color, backgroud, $background-theme-default);

@media (max-width: 1299px) {
	@include mat.typography-hierarchy($typography-config-smaller);
}
@media (min-width: 1300px) {
	@include mat.typography-hierarchy($typography-config);
}

@include mat.all-component-themes($ocm-theme);
@include mat.all-legacy-component-themes($ocm-theme);

@include opswat-material-theme($ocm-theme);
@include opswat-custom-components-theme($ocm-theme);
@include opswat-other-theme($ocm-theme);

@include app-components-theme($ocm-theme);
@include base-component-theme($ocm-theme);
@include html-component-theme-wrapped($ocm-theme);

@include my-opswat-component-theme($ocm-theme);
@include footer-component-theme($ocm-theme);
@include header-component-theme($ocm-theme);
