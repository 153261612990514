@import '@angular/material/theming';

@mixin bar-charts-theme($theme) {
	$primary: $mat-palette-primary;
	$shades: $mat-palette-shades;

	.bar-chart {
		.apexcharts-tooltip {
			border: 2px solid $primary-white !important;
			background: $bg-neutrals-950 !important;
			color: $primary-white;
		}
	}
}
