@import '@angular/material/theming';

@mixin inventory-overview-theme($theme) {
	$primary: $mat-palette-primary;
	$warn: $mat-palette-warn;
	$shades: $mat-palette-shades;
	$highlight: $mat-palette-highlight;

	.incident-card {
		background-color: mat-color($shades, A400);
		&--critical {
			color: mat-color($warn, 700);
		}
		&--high {
			color: mat-color($warn, 400);
		}
		&--medium {
			color: mat-color($highlight, 500);
		}
		&--none {
			color: mat-color($shades, 500);
		}

		&--info {
			color: mat-color($shades, 500);
		}
	}
}
