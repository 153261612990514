@import '@angular/material/theming';

@mixin free-space-chart-theme($theme) {
	$primary: $mat-palette-primary;
	$shades: $mat-palette-shades;

	.free-space {
		&--item {
			ul {
				li {
					color: $primary-dark;
				}
			}
		}
	}

	.bar-chart {
		.apexcharts-tooltip {
			border: 2px solid $primary-white !important;
			background: $bg-neutrals-950 !important;
			color: $primary-white;
		}
	}
}
