@import '@angular/material/theming';

@mixin media-firewall-page-component-theme($theme) {
	$shades: $mat-palette-shades;
	app-ocm-page-title {
		.page-title {
			//   border-bottom: 1px solid mat-color($shades, 50);
			.slash {
				color: mat-color($shades, 50);
			}
			.item-betwwen {
				color: mat-color($shades, 50);
				&:hover {
					text-decoration: none;
					color: $primary-dark;
				}
			}
		}
	}
}
