// color missing in palate
$bg-primary-950-no-palate: #081938;
$bg-neutrals-950: #777D88;

// color using in dashboard
$color-no-instance: #777D88;
$color-total-instance: #1B273C;
$color-pending-instance: #13B213;
$color-larger-than-120: #e9eaeb;
$color-smaller-than-120: #fdaf0d;
$color-smaller-than-90: #fd760d;
$color-smaller-than-30: #df6200;
$color-expired: #d00300;
$color-bg-no-report: #8E939B;
