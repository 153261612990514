@import '@angular/material/theming';

@mixin moving-to-another-group-dialog-theme($theme) {
	$shades: $mat-palette-shades;
	$success: $mat-palette-success;

	app-moving-to-another-group-dialog {
		.choosing-table {
			background-color: mat-color($shades, A700, 30%);
		}

		.apply-text {
			color: mat-color($shades, 100);
		}
	}
}
