@import '@angular/material/theming';

@mixin license-theme($theme) {
	$shades: $mat-palette-shades;
	$foreground: $foreground-theme-default;
	$warn: $mat-palette-warn;
	$primary: $mat-palette-primary;

	.grid-border {
		border: solid 1px mat-color($shades, 100);
	}

	.border-bottom {
		border-bottom: 1px solid mat-color($foreground, divider);
	}

	.expired {
		color: mat-color($warn);
	}

	.expiration-tag {
		padding: 1px 2px;
		border-radius: 0px;
		color: $primary-white;
		background-color: mat-color($warn);
		font-weight: 500;
		font-size: 12px;
	}
}
