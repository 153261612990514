@import '@angular/material/theming';

@mixin core-configuration-theme($theme) {
	$primary: $mat-palette-primary;
	$warn: $mat-palette-warn;
	$shades: $mat-palette-shades;
	$success: $mat-palette-success;
	$highlight: $mat-palette-highlight;

	app-core-configuration {
		.configuration-instance {
			&--left {
				color: mat-color($shades, 100);
			}
			&--right {
				&--apply {
					color: mat-color($shades, 100);
					font-weight: 500;
				}

				.mat-button-focus-overlay {
					background-color: transparent !important;
				}

				button {
					font-weight: 500 !important;
				}
			}
		}

		.license-info {
			&--bar {
				span,
				.license-info--deployment-id {
					padding: 10px 0;
					margin: -1px 10px;
					color: mat-color($shades, 100);
					font-weight: 500;
				}
			}

			&--status {
				padding: 1px 2px;
				border-radius: 0px;
				color: $primary-white;
				font-weight: 500;
				text-transform: uppercase;
				font-size: 12px;

				&.connected {
					background-color: $tertiary-success;
				}
				&.pending {
					background-color: $tertiary-highlight;
				}
				&.disconnected {
					background-color: $tertiary-warn;
				}
				&.license {
					background-color: $tertiary-warn;
				}
			}

			&--key {
				position: relative;
				border-bottom: 2px solid mat-color($shades, A700);

				&::after {
					content: '';
					height: 2px;
					width: 50%;
					position: absolute;
					bottom: -2px;
					left: 0;
				}
				&.gray::after {
					width: 75%;
					background-color: map-get($shades, 200);
				}

				&.black::after {
					width: 82%;
					background-color: mat-color($primary, 900);
				}

				&.warning::after {
					width: 90%;
					background-color: mat-color($highlight, 500);
				}

				&.danger::after {
					width: 96%;
					background-color: mat-color($warn, 700);
				}
			}
		}

		app-edit-field {
			.edit-label {
				font-weight: 500;
			}
		}

		.divider {
			width: 1px;
			height: 20px;
			background-color: mat-color($shades, 50);
			opacity: 0.5;
		}

		.warning {
			color: $tertiary-highlight !important;
		}

		.danger {
			color: mat-color($warn, 700);
		}
	}
}
