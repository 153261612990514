@mixin my-opswat-component-theme($theme) {
	$primary: $mat-palette-primary;

	.my-opswat-dialog {
		&__content {
			background-color: $primary-white;
		}

		.color-black {
			color: $primary-black;
		}
	}
}
