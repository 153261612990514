@import '@angular/material/theming';

@mixin kiosk-active-license-dialog-theme($theme) {
	$error: $mat-palette-warn;
	$shades: $mat-palette-shades;

	.error {
		color: mat-color($error);
	}

	.step-content-wrap {
		border: solid 1px mat-color($shades, 300);
		.tilte {
			background-color: mat-color($shades, 300);
			color: mat-color($shades, 700);
		}
	}
}
