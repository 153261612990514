@import '@angular/material/theming';

@mixin deleting-group-dialog-theme($theme) {
	$shades: $mat-palette-shades;

	app-deleting-group-dialog {
		.groups-table {
			.row:nth-child(2n + 1) {
				background-color: rgba(mat-color($shades, 50), 0.2);
			}
		}
	}
}
