@mixin app-core-group($theme) {
	$primary: $mat-palette-primary;
	$success: $mat-palette-success;

	.mat-button.rename-group-selected {
		color: mat-color($primary, 500);
	}

	.input-wrapper {
		position: relative;
	}

	.default-label {
		background-color: mat-color($success, 700);
		color: $primary-white;
		padding: 2px 8px;
		border-radius: 0px;
		font-size: 11px;

		&--input {
			margin-top: 5px;
		}
	}

	.batch-label {
		height: 18px;
		background-color: #d3d9e6;
		text-align: center;
		margin-left: 5px;
		padding: 0 5px;
		font-size: 12px;
		font-weight: 500;
		line-height: 20px;
		color: #3d4a68 !important;
		word-break: keep-all;
	}

	.circle-check {
		color: mat-color($success);
	}

	.unwanted {
		color: $tertiary-highlight;
	}
}
