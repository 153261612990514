@import '@angular/material/theming';

@mixin combo-chart-theme($theme) {
	$primary: $mat-palette-primary;
	$shades: $mat-palette-shades;

	.chart-legend {
		.item {
			.square {
				width: 14px;
				height: 14px;
				display: inline-block;
			}

			&.hidden-serie {
				// opacity: 0.5;
				.square {
					&.files {
						border: 1px solid #1b273c;
						background-color: transparent;
					}

					&.threats {
						border: 1px solid #d00300;
						background-color: transparent;
					}

					&.media {
						border: 1px solid #1b273c;
						background-color: transparent;
					}
				}
			}
			.files {
				background-color: #1b273c;
			}

			.threats {
				background-color: #d00300;
			}

			.media {
				background-color: #1b273c;
			}
			// &.threats {
			// 	border-bottom: 3px dashed mat-color($palette-opswat-warn, 700);
			// }
			// &.media {
			// 	border-bottom: 3px solid mat-color($palette-opswat-shades, A400);
			// }
		}
	}

	.border-bottom {
		border-bottom: 1px solid mat-color($shades, A700);
	}

	.no-reports-available {
		color: $primary-white;
		background-color: $color-bg-no-report;
	}
}
