@import '@angular/material/theming';

@mixin core-group-detail-configuration-theme($theme) {
	$warn: $mat-palette-warn;
	$shades: $mat-palette-shades;

	app-kiosk-group-detail-settings {
		.group-detail {
			&__label {
				color: mat-color($shades, 500, 50%);
			}

			&__tabs-line {
				background-color: mat-color($shades, A700);
			}

			&__info {
				&__card {
					&__current {
						background-color: mat-color($shades, A700);
					}

					&__nocustom {
						background-color: mat-color($shades, A700, 50%);
					}

					&__hascustom {
						background-color: $tertiary-highlight;
					}

					&__current__outofdate {
						background-color: mat-color($shades, A700);
					}

					&__outofdate {
						background-color: mat-color($warn, 700);
						color: $primary-white;
					}
				}

				&__scheduled {
					background-color: mat-color($shades, A700, 50%);
				}
			}
		}

		.group-instance {
			.warning {
				color: $tertiary-highlight;
			}

			.danger {
				color: mat-color($warn, 700);
			}
		}

		.config-empty {
			color: mat-color($shades, 500, 50%);
		}
	}
}
