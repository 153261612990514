@import '@angular/material/theming';

@mixin users-theme($theme) {
	$primary: $mat-palette-primary;
	$warn: $mat-palette-warn;
	$shades: $mat-palette-shades;
	$success: $mat-palette-success;
	$highlight: $mat-palette-highlight;

	app-users {
		.divider {
			width: 1px;
			height: 20px;
			background-color: mat-color($shades, 50);
			opacity: 0.5;
		}
	}

	.sub-filter-summary {
		font-size: 13px;
		margin-left: 26px;
		color: mat-color($palette-opswat-shades, 500, 50%);
	}
}
