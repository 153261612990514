@import '@angular/material/theming';

@mixin adding-instance-dialog-theme($theme) {
	$shades: $mat-palette-shades;
	$success: $mat-palette-success;

	app-adding-instance-dialog {
		.choosing-table {
			background-color: mat-color($shades, A700, 30%);
		}

		.apply-text {
			color: mat-color($shades, 100);
		}
		.row {
			background-color: mat-color($shades, A700, 30%);
		}

		.confirm {
			background-color: mat-color($success, 700);
			color: $primary-white;
		}

		.failure {
			background-color: $tertiary-warn;
			color: $primary-white;
		}

		.warning {
			background-color: $tertiary-highlight;
			color: $primary-white !important;
		}

		.success-panel {
			border-left: 4px solid mat-color($success, 700);
		}

		.failure-panel {
			border-left: 4px solid $tertiary-warn;
		}

		.instances-table {
			tbody tr {
				&:nth-child(2n + 1) {
					background-color: none !important;
				}
			}

			.left-column,
			.right-column {
				background-color: rgba(mat-color($shades, 50), 0.3);
			}
		}
	}
}
